<script lang="ts">
	import { onMount } from "svelte";
	import Cookies from "js-cookie";

	import SvgIcon from "./svg-icon.svelte";

	const commentTutorialCookieName = "commentTutorialBarHidden";

	let isHidden = false;

	onMount(() => {
		document.addEventListener("hide-comment-tutorial", () => {
			hide();
		});
		isHidden = Cookies.get(commentTutorialCookieName) === "true";
	});

	function hide() {
		isHidden = true;
		Cookies.set(commentTutorialCookieName, "true");
	}
</script>

<div class="comment-tutorial-bar" class:is-hidden={isHidden}>
	<div class="section">
		<img class="screenshot" src="/images/comment-screenshot.png" alt="" />

		<h6>Kommentieren Sie den Sprachkompass</h6>
		<p>Einfach den betreffenden Text markieren, um einen Kommentar hinzuzufügen. Ihre Kommentare sind <strong>nicht öffentlich</strong> und werden zur Bereicherung der im Sprachkompass veröffentlichten Texte genutzt.</p>
		
		<button type="button" class="button button-icon button-close" title="Nicht mehr anzeigen" on:click={hide}>
			<SvgIcon name="close" />
		</button>
	</div>
</div>
