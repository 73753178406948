<script lang="ts">
	import { onMount, tick } from "svelte";
	import ky from "ky";
	import { create, enforce, test, group, skip } from "vest";
	import "vest/enforce/email";
	import toast, { Toaster } from "@leodog896/svelte-french-toast";
	import { TextInput, Button, Textarea, SvelteUIProvider, createTheme } from "@svelteuidev/core";
	import type { ProblemDocument } from "http-problem-details";

	import SvgIcon from "./svg-icon.svelte";
	import ToastContent from "./toast-content.svelte";
	import { ValidatingStore, type ValidationProblemDetails } from "./validating-store";
	import type { FeedbackRequest } from "./models";
	import { FormState, measureElement, theme } from "./utils";

	const headlines = "h1, h2, h3, h4, h5, h6";
	const commentableElements = "p, li, figure, h2, h3, h4, h5, h6, .section";

	let isFormOpen = false;
	let hasSelection = false;

	const initialFormData: FeedbackRequest = {
		fullname: "",
		email: "",
		comment: "",
		sectionInformation: [],
		commentedContent: "",
	};

	let state: FormState = FormState.Ready;
	let token: string = "";
	let selectedRangeRects: Iterable<DOMRect> = [];
	let selectedRangeContainer: HTMLElement;
	let commentButtonStyle = "";
	let commentFormStyle = "";
	let textareaContainer: HTMLElement;

	const store = new ValidatingStore(initialFormData, (data: FeedbackRequest) => {
		test("fullname", "Bitte geben Sie Ihren Namen an", () => {
			enforce(data.fullname).isNotBlank();
		});
		test("email", () => {
			enforce(data.email).message("Bitte geben Sie Ihre E-Mail-Adresse an").isNotBlank().message("Bitte geben Sie eine gültige E-Mail-Adresse an").isEmail();
		});
		test("comment", "Bitte geben Sie einen Kommentar ein", () => {
			enforce(data.comment).isNotBlank();
		});
	});

	onMount(() => {
		let tokenElement = document.getElementById("RequestVerificationToken");
		if (tokenElement == null || tokenElement.textContent == null) {
			throw new Error("Could not load XSRF token.");
		}
		token = JSON.parse(tokenElement.textContent);

		document.addEventListener("selectionchange", onSelectionChange);
	});

	async function onCommentButtonClicked() {
		document.dispatchEvent(new CustomEvent("hide-comment-tutorial"));

		let selection = window.getSelection();
		if (selection == null) {
			return;
		}

		if (!selection.isCollapsed && selection.rangeCount > 0) {
			let range = document.getSelection()!.getRangeAt(0);

			let container = range.commonAncestorContainer as HTMLElement;
			if (range.commonAncestorContainer.nodeType === Node.TEXT_NODE) {
				container = container.parentElement!;
			}
			selectedRangeContainer = container;
			selectedRangeRects = range.getClientRects();

			let commentableElement = container;
			if (!commentableElement.matches(commentableElements)) {
				commentableElement = commentableElement.closest(commentableElements)!;
			}

			if (commentableElement.nodeName === "FIGURE") {
				$store.commentedContent.value = commentableElement.querySelector("figcaption")?.textContent ?? selection.toString();
			} else {
				$store.commentedContent.value = selection.toString();
			}

			store.updateData((state) => {
				state.sectionInformation = Array.from(document.querySelectorAll<HTMLElement>(".article-index li.active > a"))
					.map((e) => e.textContent ?? "")
					.slice(0, 3);
				return state;
			});

			isFormOpen = true;
			await tick();
			textareaContainer.querySelector("textarea")?.focus();
		}
	}

	function onSelectionChange() {
		let selection = window.getSelection();
		if (isFormOpen) {
			return;
		}
		if (selection == null || selection.isCollapsed || selection.rangeCount === 0) {
			onClearSelection();
			return;
		}

		let range = selection.getRangeAt(0);

		let container = range.commonAncestorContainer as HTMLElement;
		if (range.commonAncestorContainer.nodeType === Node.TEXT_NODE) {
			container = container.parentElement!;
		}

		if (!container.matches(commentableElements)) {
			container = container.closest(commentableElements)!;
		}

		if (container != null && container.closest(".comment-box") == null) {
			let boundingBox = range.getBoundingClientRect();
			let containerMeasurements = measureElement(container);
			
			commentButtonStyle = `transform: translate3d(${boundingBox.right}px, ${boundingBox.top + window.scrollY - 4.5}px, 0);`;

			if (container.classList.contains("section")) {
				commentFormStyle = `
					width: ${containerMeasurements.width}px;
					transform: translate3d(${containerMeasurements.marginLeft + containerMeasurements.paddingLeft}px, ${boundingBox.bottom + window.scrollY}px, 0);
				`;
			} else {
				commentFormStyle = `
					width: ${containerMeasurements.width}px;
					transform: translate3d(${containerMeasurements.left}px, ${boundingBox.bottom + window.scrollY}px, 0);
				`;
			}

			window.addEventListener("mouseup", e => {
				hasSelection = true;
			}, { once: true });
		}
	}

	function onClearSelection() {
		store.clear();
		selectedRangeRects = [];
		isFormOpen = false;
		hasSelection = false;
	}

	async function submitAsync() {
		if (state !== FormState.Ready) {
			return;
		}
		if (!store.validate()) {
			return;
		}

		try {
			state = FormState.Loading;
			let response = await ky.post(`/api/feedback`, {
				headers: { RequestVerificationToken: token },
				json: store.getData(),
				throwHttpErrors: false,
			});

			if (response.ok) {
				state = FormState.Success;
				onClearSelection();
				toast.success(ToastContent, {
					props: {
						messageHtml: `Vielen Dank für Ihren Kommentar. Sie helfen mit, den Sprachkompass zu bereichern.`,
					},
				});
			} else if (response.status === 400) {
				state = FormState.Ready;
				let problem = await response.json<ValidationProblemDetails>();
				store.setExternalErrors(problem);
			} else {
				let problem = await response.json<ProblemDocument>();
				toast.error(ToastContent, {
					props: {
						problem,
						messageHtml: `Leider konnte Ihre Nachricht nicht versendet werden. Bitte nehmen Sie direkt via E-Mail an <a href="mailto:info@sprachkompass.ch">info@sprachkompass.ch</a> mit uns Kontakt auf.`,
					},
				});
				state = FormState.Error;
			}
		} catch (e) {
			state = FormState.Error;
			toast.error(ToastContent, {
				props: {
					messageHtml: `Leider konnte Ihre Nachricht nicht versendet werden. Bitte nehmen Sie direkt via E-Mail an <a href="mailto:info@sprachkompass.ch">info@sprachkompass.ch</a> mit uns Kontakt auf.`,
				},
			});
		}
	}

	function getHighlighterStyle(rect: DOMRect) {
		let containerStyle = window.getComputedStyle(selectedRangeContainer);
		return `
			position: absolute;
			top: ${rect.top + parseInt(containerStyle.marginTop, 10) + window.scrollY - 4.5}px;
			left: ${rect.left}px;
			width: ${rect.width}px;
			height: ${containerStyle.lineHeight};
		`;
	}
</script>

<span class="comment-textwrapper">
	{#each selectedRangeRects as rect}
		<span style={getHighlighterStyle(rect)}></span>
	{/each}
</span>

<div class="comment-box" class:is-open={isFormOpen}>
	<button type="button" class="button button-icon button-comment" title="Kommentieren" class:is-visible={hasSelection} style={commentButtonStyle} on:click={onCommentButtonClicked}>
		<SvgIcon name="chat" />
	</button>
	<div class="comment-form" style={commentFormStyle}>
		<SvelteUIProvider {theme}>
			<div class="contact-form">
				<p>
					<Textarea bind:element={textareaContainer} label="Kommentar" rows={6} cols={40} invalid={$store.comment.hasErrors} error={$store.comment.errors.join(", ")} on:blur={() => $store.comment.touch()} bind:value={$store.comment.value} />
				</p>
				<p>
					<TextInput label="Vor- und Nachname" autocomplete="name" invalid={$store.fullname.hasErrors} error={$store.fullname.errors.join(", ")} on:blur={() => $store.fullname.touch()} bind:value={$store.fullname.value} />
				</p>
				<p>
					<TextInput label="E-Mail" autocomplete="email" invalid={$store.email.hasErrors} error={$store.email.errors.join(", ")} on:blur={() => $store.email.touch()} bind:value={$store.email.value} />
				</p>
				<p>
					<Button class="button-send" color="primary" size="md" loading={state === FormState.Loading} on:click={() => submitAsync()}>Absenden</Button>
					<Button class="button-outline button-cancel" variant="outline" color="primary" size="md" disabled={state === FormState.Loading} on:click={() => onClearSelection()}>Abbrechen</Button>
				</p>
			</div>
		</SvelteUIProvider>
	</div>
</div>
<Toaster />
