<script lang="ts">
	import type { ProblemDocument } from "http-problem-details";

	export let toast;

	export let messageHtml: string;
	export let problem: ProblemDocument | null = null;
</script>

<div>
	<div>{@html messageHtml}</div>
	{#if problem}
		<div>
			<small>
				<em>{problem?.title}</em><br />
				{problem?.detail}
			</small>
		</div>
	{/if}
</div>
